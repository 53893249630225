// 這裡放一些共用的設定值

const tagKeyName = '空間風格';
const cityLocationClassify = {
    '北部': [1, 2, 7, 17, 3, 8, 9],
    '中部': [10, 4, 11, 12, 13],
    '南部': [15, 14, 5, 6, 16],
    '東部': [18, 19],
    '離島': [20, 21, 22]
};

const topCityList = [
    {
        name: '台北市',
        imgName: '台北市',
        cityId: 1
    },
    {
        name: '台中市',
        imgName: '台中市',
        cityId: 4
    },
    {
        name: '新北市',
        imgName: '新北市',
        cityId: 2
    },
    {
        name: '高雄市',
        imgName: '高雄市',
        cityId: 6
    }
]

/*
id img 對照文件
* https://docs.google.com/spreadsheets/d/1L9g60lrBQf8hMkEBxsL7010_LvAIRZwobmcDAYBMfMs/edit#gid=0
* */
const categoryIdImgDev = {
    "5": "meeting_seminar_classroom",
    "7": "private_office",
    "6": "shared_seat",
    "20": "gathering_space",
    "24": "salon_beauty_nails",
    "9": "event_space",
    "8": "sports_space",
    "30": "rental_studio",
    "22": "cafe_restaurant",
    "77": "kitchen",
    "13": "photo_studio",
    "28": "gym",
    "29": "sauna",
    "14": "board_game",
    "11": "art_performance_space",
    "39": "ktv_party_club",
    "23": "other_type",
    "49": "meeting_lecture",
    "50": "course_training",
    "51": "gathering_party",
    "52": "event",
    "53": "shooting_recording",
    "54": "sports_fitness",
    "55": "beauty_grooming",
    "56": "music_drama",
    "57": "hobby_handcraft",
    "58": "art_activity",
    "59": "other_usage"
}
const categoryIdImgProd = {
    "5": "meeting_seminar_classroom",
    "7": "private_office",
    "6": "shared_seat",
    "44": "gathering_space",
    "11": "salon_beauty_nails",
    "9": "event_space",
    "45": "sports_space",
    "46": "rental_studio",
    "8": "cafe_restaurant",
    "12": "kitchen",
    "13": "photo_studio",
    "48": "gym",
    "49": "sauna",
    "14": "board_game",
    "20": "art_performance_space",
    "10": "ktv_party_club",
    "15": "other_type",
    "50": "meeting_lecture",
    "51": "course_training",
    "52": "gathering_party",
    "53": "event",
    "54": "shooting_recording",
    "55": "sports_fitness",
    "56": "beauty_grooming",
    "57": "music_drama",
    "58": "hobby_handcraft",
    "59": "art_activity",
    "60": "other_usage"
}

const articleUrlToId = {
    'meeting-types-and-place-rentals': '117',
    'black-and-white-chef-restaurant-guide': '115',
    'place-rental-guide': "114",
    'how-to-find-the-perfect-place': '113',
    'jur-pace-venue-overview': '111',
    'babys-first-birthday-ceremony': '110',
    'dining-and-event-gathering-recommendations': '107',
    'board-game-place': '104',
    'locations-for-coser-photoshoots': '102',
    'studios-hoping-u': '95',
    'perfect-display-place': '91',
    'photography-studio-rental': '89',
    'future-trends-in-office': '41',
    'black-myth-wukong': '40',
    'mid-autumn-bbq-place': '39'
}

const blogArticleList = [
    {id:'122', slug: 'christmas-event-venue'},
    {id: '121', slug: 'rental-change-life'},
    {id: '119', slug: 'space-rental-platform'},
    {id: '117', slug: 'meeting-types-and-space-rentals'},
    {id: '115', slug: 'bblack-and-white-chef-shared-kitchen'},
    {id: '114', slug: 'place-rental-guide'},
    {id: '113', slug: 'how-to-find-the-perfect-place'},
    {id: '111', slug: 'jur-pace-venue-overview'},
    {id: '110', slug: 'babys-first-birthday-ceremony'},
    {id: '107', slug: 'dining-and-gathering-place'},
    {id: '104', slug: 'board-game-place'},
    {id: '102', slug: 'coser-photoshoots-place'},
    {id: '95', slug: 'studios-hoping-u'},
    {id: '91', slug: 'perfect-display-place'},
    {id: '89', slug: 'photography-studio-rental'},
    {id: '41', slug: 'future-trends-in-office'},
    {id: '40', slug: 'black-myth-wukong'},
    {id: '39', slug: 'mid-autumn-bbq-place'}
]


// 日富 1-7
const spaceLinkToWebsite = {
    '46812167632279': 'https://www.rdspacetw.com/cart/21/20',
    '49938966978046': 'https://www.rdspacetw.com/cart/22/28',
    '50763246295391': 'https://www.rdspacetw.com/cart/23/23',
    '51381942043993': 'https://www.rdspacetw.com/cart/24/19',
    '52000247152702': 'https://www.rdspacetw.com/cart/25/24',
    '52850383724990': 'https://www.rdspacetw.com/cart/26/25',
    '53335813461516': 'https://www.rdspacetw.com/cart/27/26',
}

const priceList=[
    {id: 0, name: '不限價格'},
    {id: 1, name: '100-500元'},
    {id: 2, name: '500-1000元'},
    {id: 3, name: '1000-2000元'},
    {id: 4, name: '2000-3000元'},
    {id: 5, name: '3000-5000元'},
    {id: 6, name: '5000-10000元'},
    {id: 7, name: '10000元以上'},
]

const priceRange={
    0: {},
    1: { "index": "all_price",min: 100, max: 500},
    2: { "index": "all_price",min: 500, max: 1000},
    3: { "index": "all_price",min: 1000, max: 2000},
    4: { "index": "all_price",min: 2000, max: 3000},
    5: { "index": "all_price",min: 3000, max: 5000},
    6: { "index": "all_price",min: 5000, max: 10000},
    7: { "index": "all_price",min: 10001, max: 999999},
}

const peopleList = [
    {id: 0, name: '不限人數'},
    {id: 1, name: '1-5人'},
    {id: 2, name: '6-10人'},
    {id: 3, name: '11-20人'},
    {id: 4, name: '21-30人'},
    {id: 5, name: '31-50人'},
    {id: 6, name: '51-100人'},
    {id: 7, name: '100人以上'},
]

const peopleCapRange={
    0: {},
    1: {min: 1, max: 5},
    2: {min: 6, max: 10},
    3: {min: 11, max: 20},
    4: {min: 21, max: 30},
    5: {min: 31, max: 50},
    6: {min: 51, max: 100},
    7: {min: 101, max: 9999},
}

const roomSizeList = [
    {id: 0, name: '不限坪數'},
    {id: 1, name: '10坪以下'},
    {id: 2, name: '11-20坪'},
    {id: 3, name: '21-30坪'},
    {id: 4, name: '31-40坪'},
    {id: 5, name: '41-50坪'},
    {id: 6, name: '51-100坪'},
    {id: 7, name: '100坪以上'},
]

const roomSizeRange={
    0: {},
    1: {min: 1, max: 10},
    2: {min: 11, max: 20},
    3: {min: 21, max: 30},
    4: {min: 31, max: 40},
    5: {min: 41, max: 50},
    6: {min: 51, max: 100},
    7: {min: 101, max: 9999},
}



export {
    cityLocationClassify,
    tagKeyName,
    topCityList,
    categoryIdImgDev,
    categoryIdImgProd,
    blogArticleList,
    spaceLinkToWebsite,
    priceList,
    priceRange,
    peopleList,
    peopleCapRange,
    roomSizeList,
    roomSizeRange
};
