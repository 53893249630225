<template>
  <canvas ref="captchaCanvas"
          class="cursor-pointer"
          @click.prevent="refreshCode"/>
</template>

<script setup>
import {ref, onMounted} from 'vue';

const props = defineProps({
  width: {
    type: Number,
    default: 170
  },
  height: {
    type: Number,
    default: 50
  }
})


const captcha = ref('');
const captchaCanvas = ref(null);
const randomNum = (min, max) => Math.floor(Math.random() * (max - min) + min);
const randomColor = (min, max) => `rgb(${randomNum(min, max)},${randomNum(min, max)},${randomNum(min, max)})`;

defineExpose({
  captcha,
  refreshCode
});

onMounted(() => {
  // 生成驗證碼
  captcha.value = randomCode();
  writeAuthCode();
});

const writeAuthCode = () => {
  const canvas = captchaCanvas.value;

  // 建立一個canvas物件
  const ctx = canvas.getContext('2d');
  ctx.textBaseline = 'middle';

  //canvas 尺寸
  canvas.width = props.width || 300
  canvas.height = props.height || 150

  // 這個範圍的顏色作背景看起來清晰一些
  ctx.fillStyle = randomColor(180, 255);
  ctx.fillRect(0, 0, props.width, props.height);

  for (let i = 0; i < captcha.value.length; i++) {
    const txt = captcha.value.charAt(i);
    ctx.font = '2.5em SimHei';
    // 隨機生成字型顏色
    ctx.fillStyle = randomColor(50, 160);
    ctx.shadowOffsetY = randomNum(-3, 3);
    ctx.shadowBlur = randomNum(-3, 3);
    ctx.shadowColor = 'rgba(0, 0, 0, 0.3)';
    const x = props.width / (captcha.value.length + 1) * (i + 1);
    // const y = props.height / 2;
    const y = props.height / 2 + randomNum(5, 15); // 調整文字垂直位置
    const deg = randomNum(-30, 30);
    // 設定旋轉角度和座標原點
    ctx.translate(x, y);
    ctx.rotate(deg * Math.PI / 180);
    ctx.fillText(txt, 0, 0);
    // 恢復旋轉角度和座標原點
    ctx.rotate(-deg * Math.PI / 180);
    ctx.translate(-x, -y);
  }

// 1~4條隨機干擾線隨機出現
  for (let i = 0; i < randomNum(1, 4); i++) {
    ctx.strokeStyle = randomColor(40, 180);
    ctx.beginPath();
    ctx.moveTo(randomNum(0, props.width), randomNum(0, props.height));
    ctx.lineTo(randomNum(0, props.width), randomNum(0, props.height));
    ctx.stroke();
  }
// 繪製干擾點
  for (let i = 0; i < props.width / 6; i++) {
    ctx.fillStyle = randomColor(0, 255);
    ctx.beginPath();
    ctx.arc(randomNum(0, props.width), randomNum(0, props.height), 1, 0, 2 * Math.PI);
    ctx.fill();
  }
};

function refreshCode() {
  captcha.value = randomCode();
  writeAuthCode();
  // console.log('refreshCode', captcha.value);
};

const randomCode = () => {
  const str = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let code = '';
  for (let i = 0; i < 4; i++) {
    const txt = str[randomNum(0, str.length)];
    code += txt;
  }
  return code;
};

</script>
<style scoped
       lang="scss">

</style>
