<template>
  <div class="relative">
    <input class='border-2 border-osn-grey-02 text-[#7A7A7A] text-[16px] my-0
      w-full rounded-full px-4 py-1 h-[50px] focus:border-osn-yellow-00 outline-none'
           :class="isValid?'border-osn-yellow-00':'border-osn-red-00'"
           ref="inputField"
           :type="inputTypeState"
           :placeholder="placeholder"
           :name="name"
           :autocomplete="autocomplete"
           :maxlength="maxlength"/>

    <!-- 顯示/隱藏密碼 -->
    <div v-show="type==='password'"
         class="absolute top-4 right-5 cursor-pointer rounded-full"
         v-on:click="handleTogglePassword">
      <el-icon :size="25" v-show="inputTypeState==='password'"><Hide /></el-icon>
      <el-icon :size="25" v-show="inputTypeState==='text'"><View /></el-icon>
    </div>

    <div v-if="isValid" class=" mt-[12px] ml-[12px] leading-none text-[14px] text-[#7A7A7A]">{{comment}}</div>
    <!-- 錯誤訊息 -->
    <div class="mt-[12px] ml-[12px] leading-none text-[14px] text-osn-red-00 flex gap-2"
         v-if="!isValid">{{ alert }}
    </div>
  </div>
</template>
<script setup>
import {onMounted, ref} from "vue";
import {View,Hide} from '@element-plus/icons-vue'

const props = defineProps({
  placeholder: {
    type: String,
    default: ''
  },
  comment: {
    type: String,
    default: ''
  },
  type: {
    type: String,
    default: 'text'
  },
  isValid: {
    type: Boolean,
    default: true
  },
  alert: {
    type: String,
    default: ''
  },
  autocomplete: {
    type: String,
    default: 'true'
  },
  name: {
    type: String,
    default: ''
  },
  maxlength:{
    type: String,
    default: "40"
  },
  registerPassword: {
    type: Boolean,
    default: false
  }
})

const inputTypeState = ref('')
const inputField = ref(null);

onMounted(async () => {
  inputTypeState.value = props.type
})

const handleTogglePassword = () => {
  inputTypeState.value = inputTypeState.value === 'password' ? 'text' : 'password'
  inputField.value.focus()
}


</script>
<style scoped
       lang="scss">

</style>
