import validate from "/app/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_encoding@0.1.1_vjysjksgobfzzmouuwb753feiu/node_modules/nuxt/dist/pages/runtime/validate.js";
import redirect_45to_45home_45global from "/app/middleware/redirect-to-home.global.ts";
import run_45global from "/app/middleware/run.global.ts";
import manifest_45route_45rule from "/app/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_encoding@0.1.1_vjysjksgobfzzmouuwb753feiu/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  redirect_45to_45home_45global,
  run_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/app/middleware/auth.ts"),
  "check-order-id": () => import("/app/middleware/checkOrderID.ts"),
  "is-login": () => import("/app/middleware/isLogin.ts"),
  "login-dialog": () => import("/app/middleware/loginDialog.ts"),
  phone: () => import("/app/middleware/phone.ts")
}