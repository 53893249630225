import httpRequest from "~/composables/requset";
import type {ApiResponse, GetUserInfoResponse} from '~/utils/types';


// 取得使用者資訊
export function  getUserInfo():Promise<GetUserInfoResponse> {
    return httpRequest(
        'POST',
        '/api/user/info'
    )
}
// 忘記密碼
export function  forgotPassword(emailAddress:string):Promise<ApiResponse> {
    const queryData = {
        email: emailAddress
    }
    return httpRequest(
        'POST',
        '/api/user/forgot-password',
        queryData
    )
}
// 檢查使用者帳號是否存在
export function  checkAccount(data):Promise<ApiResponse> {
    return httpRequest(
        'POST',
        '/api/user/check-account',
        data
    )
}

// 編輯名稱
export function  editName(data):Promise<ApiResponse> {
    return httpRequest(
        'POST',
        '/api/user/edit-name',
        data
    )
}

// 編輯名稱
export function  editPhone(data):Promise<ApiResponse> {
    return httpRequest(
        'POST',
        '/api/user/edit-phone',
        data
    )
}

// 更改密碼
export function  editPassword(data):Promise<ApiResponse> {
    return httpRequest(
        'POST',
        '/api/user/edit-password',
        data
    )
}

// 手機驗證收簡訊驗證碼
export function  getCellPhoneVerificationCode(data):Promise<ApiResponse> {
    return httpRequest(
        'POST',
        '/api/user/verification/sms/code',
        data
    )
}

// 手機驗證
export function  setCellPhoneVerification(data):Promise<ApiResponse> {
    return httpRequest(
        'POST',
        '/api/user/verification/sms',
        data
    )
}
