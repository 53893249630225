import {defineStore} from 'pinia'
import {getUserInfo} from "@/api/user";
import {logout} from "@/api/auth";
import {useAuth} from "@/composables/useAuth";
import type {GetUserInfoResponse} from "~/utils/types";

interface useUserStoreState {
    account: string,
    token: string,
    name: string,
    phone: string,
    email: string,
    accountType: number
}


export const useUserStore = defineStore('user', {
    state: (): useUserStoreState => {
        return {
            account: '',
            token: '',
            name: '',
            phone: '',
            email: '',
            accountType: -1
        }
    },
    getters: {
        getToken: (state) => {
            return state.token
        },
        getAccount: (state) => {
            return state.account
        },
        getName: (state) => {
            return state.name
        },
        getPhone: (state) => {
            return state.phone
        },
        getEmail: (state) => {
            return state.email
        },
        getAccountType: (state) => {
            return state.accountType
        },

    },
    actions: {
        async updateUserInfo(): Promise<void> {
            try {
                const res: GetUserInfoResponse = await getUserInfo();
                this.updateAccount(res.account);
                this.updateName(res.name);
                this.updatePhone(res.phone);
                this.updateEmail(res.email);
                this.updateAccountType(res.account_type);
            } catch (error) {
                console.log(error);
            }
        },
        async logout(): Promise<void> {

            try {
                await logout(this.account)
            } catch (error) {
                console.log(error)
            }
            this.updateAccount('');
            this.updateToken('');
            this.updateName('');
            this.updatePhone('');
            this.updateEmail('');
            this.updateAccountType(-1);
        },
        updateAccount(email: string): void {
            this.account = email
        },
        updateToken(tokenString: string): void {
            this.token = tokenString
           /* const {setToken} = useAuth()
            setToken(tokenString)*/
        },
        updateName(nameString: string): void {
            this.name = nameString
        },
        updatePhone(phoneString: string): void {
            this.phone = phoneString
        },
        updateEmail(emailString: string): void {
            this.email = emailString
        },
        updateAccountType(type: number): void {
            this.accountType = type
        }
    },
    persist: {
        storage: persistedState.cookiesWithOptions({}),
    },
})

