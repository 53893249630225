export default defineNuxtPlugin((nuxtApp) => {
    const loadGoogleMaps = () => {
        if (window.google && window.google.maps) {
            return Promise.resolve(window.google.maps);
        }
        return new Promise((resolve, reject) => {
            const script = document.createElement('script')
            script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBotYLf8F9luGJ-byazhKqJ1t-YZvlNBD0&callback=initMap&loading=async`
            script.async = true
            script.defer = true
            /*script.onload = () => {
                resolve(google.maps)
            }*/

            window.initMap = () => {
                resolve(window.google.maps)
            }

            script.onerror = () => {
                reject(new Error('Google Maps 載入失敗'))
            }
            document.head.appendChild(script)
        })
    }

    nuxtApp.provide('loadGoogleMaps', loadGoogleMaps)
})
