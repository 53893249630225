import {defineStore} from 'pinia'
import {tagKeyName} from '@/config/common'
import {ref} from "vue";

const typeCategoryId = import.meta.env.VITE_APP_BASE_TYPE_CATEGORY_ID
const tagCategoryId = import.meta.env.VITE_APP_BASE_TAG_CATEGORY_ID
const usageCategoryId = import.meta.env.VITE_APP_BASE_USAGE_CATEGORY_ID

function findTagObjectBySpaceId(data, id) {
    for (let i = 0; i < data.length; i++) {
        const obj = data[i]
        if (obj.id === id) {
            return obj
        } else if (obj.subs && obj.subs.length > 0) {
            const found = findTagObjectBySpaceId(obj.subs, id)
            if (found) {
                return found
            }
        }
    }
    return {}
}

function findObjectById(data, targetId) {
    for (let item of data) {
        if (item.id === targetId) {
            return item;
        }
        if (item.subs && item.subs.length > 0) {
            let result = findObjectById(item.subs, targetId);
            if (result) {
                return result;
            }
        }
    }
    return {};
}

export const useCategoryStore = defineStore('category', {
    state: () => ({
        categories: [],
        spaceTypeList: [],
        amounts: [],
        selectedCategoryIdList: [], // 新的api要分開了,
        selectedCategoryObject: {}

    }),
    getters: {
        // 拿空間類型 name 找 Category id
        getCategoryIdBySpaceTypeName: (state) => (spaceTypeName) => {
            const findSpaceItem = state.spaceTypeList.find(item => item.name === spaceTypeName);
            if (findSpaceItem) {
                return findSpaceItem.id
            } else {
                return -1
            }
        },

        // 用 categoryId 找 空間類型的 name
        getSpaceTypeNameById: (state) => (categoryId) => {
            if (categoryId === -1) {
                return '所有空間類型';
            }
            const spaceTypeName = findObjectById(state.spaceTypeList, categoryId)?.name;
            return spaceTypeName ? spaceTypeName : '';
        },

        // 找空間類型列表 好像會有太慢回來的問題
        getSpaceTypeCategories: (state) => {
            return state.categories.find(item => Number(item.id) === Number(typeCategoryId))?.subs
        },

        // 找空間風格列表 沒有相依性
        getTagCategories: (state) => {
            return state.categories.find(item => Number(item.id) === Number(tagCategoryId))?.subs
        },

        // 找空間用途列表
        getUsageCategories: (state) => {
            return state.categories.find(item => Number(item.id) === Number(usageCategoryId))?.subs
        },

        // 找單一有相依性的空間風格
        getCategoryObjById: (state) => (categoryId) => {
            return findTagObjectBySpaceId(state.categories, categoryId)
        },

        getSpaceTypeObj: (state) => (categoryId) => {
            const targetSpaceTypeList = state.categories.find(obj => obj.id === Number(typeCategoryId))
            if (!targetSpaceTypeList) {
                return {}
            }
            const index = targetSpaceTypeList.subs.findIndex(obj => obj.id === categoryId)
            if (index !== -1) {
                return targetSpaceTypeList.subs[index]
            } else {
                return {}
            }
        },

        // 把空間類型的 Category id 傳進來找該空間底下相依的風格tag的id
        getCategoryTagIdBySpaceType: (state, getters) => (SpaceTypeId) => {
            const list = getters.getSpaceTypeObj(SpaceTypeId).subs
            return list.filter(obj => obj.name === tagKeyName)[0].id
        },

        // 空間類型下拉
        getSpaceTypeDropdown(state) {
            const spaceTypeTitleArr = state.spaceTypeList.map(value => {
                return value.name
            })
            spaceTypeTitleArr.unshift("所有空間類型")
            return spaceTypeTitleArr
        },
        getTagListBySpaceName: (state) => (spaceTypeName) => {
            const findSpaceItem = state.spaceTypeList.find(item => item.name === spaceTypeName);
            if (findSpaceItem?.subs.length > 0) {
                return findSpaceItem.subs[0].subs
            } else {
                return []
            }
        },
        getCityAmountListById: (state) => (spaceTypeCategoryId) => {
            return state.amounts.types[spaceTypeCategoryId]
        },
        getSelectedCategoryIdList: (state) => {
            return state.selectedCategoryIdList
        },

        // 新的搜尋api用
        getSelectedCategoryObject: (state) => {
            return state.selectedCategoryObject
        },


    },
    // action 支持 async/await 的语法，可以自行添加使用
    // action 里的方法相互之间调用，直接用 this 访问即可
    actions:
        {
            setCategories(categories) {
                this.categories = categories
            },
            setSpaceTypeList(spaceTypeList) {
                this.spaceTypeList = spaceTypeList
            },
            setAmounts(amounts) {
                this.amounts = amounts
            },
            // 目前只有tag塞進來
            // 新的 api 要把 typeCategoryId tagCategoryId usageCategoryId 分開存了
            setSelectedCategoryIdList(selectedList) {
                this.selectedCategoryIdList = selectedList
            },
            clearSelectedCategoryIdList() {
                this.selectedCategoryIdList.length = 0
            },

            setSelectedCategoryObject(categorySelectObj) {
                this.selectedCategoryObject = {...this.selectedCategoryObject, ...categorySelectObj}
            },
            cleanSelectedCategoryObject(){
                this.selectedCategoryObject={}
            }
        }
})
