import {defineStore} from 'pinia'

export const useCityStore = defineStore('city', {
    state: () => ({
        /*
         cities:[ {
	        "city_id":0
	        "city_name":"台北市"
	        "longitude":121.12312
	        "latitude":20.123123
	        "amount":10
           }]
         */
        cityList: [],
        selectedCityIDList: []
    }),
    getters: {
        getCityAmountSum: (state) => {
            let amount = 0
            state.cityList.forEach(city => {
                amount += city.amount
            })
            return amount
        },
        getCityList: (state) => {
            return state.cityList
        },
        getSelectedCityIDList: (state) => {
            return state.selectedCityIDList
        },
        getCityNameList: (state) => {
            return state.cityList.map(item => item.city_name)
        },
        getCityProperty: (state) => (cityId, property) => {
            const city = state.cityList.find(city => city.city_id === cityId);
            return city ? city[property] : null;
        },
        getUpdatedCityList: (state) => (cityList, cityAmountsBySpaceType) => {
            // 使用map建立城市列表的副本
            const updatedCityList = cityList.map(city => {
                // 取得城市的city_id
                const cityId = city.city_id.toString();

                // check 城市是否在不同空間類型的 amount obj 中存在
                if (cityAmountsBySpaceType.hasOwnProperty(cityId)) {
                    // return 新 obj，更新 amount 的值
                    return {...city, amount: cityAmountsBySpaceType[cityId]};
                }

                // 如果不同空間類型的 amount obj 在該城市沒有空間，則 amount 設為 0
                return { ...city, amount: 0 };
            });
            // 按 amount 大到小排序，所有地區排在最前面
            updatedCityList.sort((a, b) => {
                if (a.city_id === 0) {
                    return -1; // a排在前面
                } else if (b.city_id === 0) {
                    return 1; // b排在前面
                } else {
                    return b.amount - a.amount; // 按 amount 大到小排序
                }
            });

            return updatedCityList;
        }


    },
    actions: {
        setCityList(cityList) {
            this.cityList = cityList
        },
        setSelectedCityID(cityID) {
            this.clearCitySelected()
            this.selectedCityIDList.push(cityID)
        },
        clearCitySelected() {
            this.selectedCityIDList.length = 0
        }
    }
})

