import {defineStore} from 'pinia'

export const useSettingStore = defineStore('setting', {
    state: () => ({
        showLog: false,
        mobile: true,
        homeRouter: '/',
        mapZoom: 14,
        loginDialogVisible: false,
        mobileCompanyListVisible: false,
    }),
    getters: {
        isMobile: (state) => {
            return state.mobile
        },
        isShowLog: (state) => {
            return state.showLog
        },
        getHomeRouter: (state) => {
            return state.homeRouter
        },
        getMapZoom: (state) => {
            return state.mapZoom
        },
        isLoginDialogVisible: (state) => {
            return state.loginDialogVisible
        },
        isMobileCompanyListVisible: (state) => {
            return state.mobileCompanyListVisible
        }
    },
    actions: {
        updateIsShowLog(logValue) {
            this.showLog = logValue
        },
        updateIsMobile(mobileValue) {
            this.mobile = mobileValue
        },
        updateHomeRouter(aid) {
            this.homeRouter = aid
        },
        updateMapZoom(zoom) {
            this.mapZoom = zoom
        },
        updateLoginDialogVisible(loginDialogVisible) {
            this.loginDialogVisible = loginDialogVisible
        },
        updateMobileCompanyListVisible(mobileCompanyListVisible) {
            this.mobileCompanyListVisible = mobileCompanyListVisible
        },
        toggleMobileCompanyListVisible() {
            this.mobileCompanyListVisible = !this.mobileCompanyListVisible
        }
    },
    persist: {
        storage: persistedState.cookiesWithOptions({
            sameSite: 'strict',
        }),
        // 切換空間列表不需要存？
        paths: ['showLog','mobile','homeRouter','mapZoom','loginDialogVisible']
    }
})

