import { createI18n } from 'vue-i18n'
import en from '../locales/en-US.js'
import tw from '../locales/zh-TW.js'


const i18n = createI18n({
    legacy: false,
    locale: "tw",
    fallbackLocale: "tw",
    globalInjection: true,
    messages: {
        "tw": tw,
        "en": en
    }
});

export default i18n
