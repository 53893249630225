import {parseRentType} from "./parseTool.js";

export function accountRegex(value) {
    const accountPattern = /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/
    const accountRegex = new RegExp(accountPattern)

    return accountRegex.test(value)
}

export function passwordRegex(value) {
    const passwordPattern = /^[a-zA-Z0-9]{8,20}$/
    const passwordRegex = new RegExp(passwordPattern)

    return passwordRegex.test(value)
}

export function nameRegex(value) {
    if (value.trim() === "") {
        return false;
    }
    const pattern = /^[a-zA-Z0-9\u4e00-\u9fa5\s]{1,15}$/;
    const regex = new RegExp(pattern)

    return regex.test(value.trim())
}

export function cellPhoneRegex(value) {
    if (value.trim() === "") {
        return false;
    }
    const pattern = /^09\d{8}$/
    const regex = new RegExp(pattern)

    return regex.test(value.trim())
}

export function limitRegex(value,number) {
    if (value.trim() === "") {
        return false;
    }
    const pattern = new RegExp(`\\d{${Number(number)}}`);
    const regex = new RegExp(pattern)

    return regex.test(value.trim())
}

export function creditCardExpiryRegex(value) {
    if (value.trim() === "") {
        return false;
    }
    const pattern = /((0([1-9]))|(1(0|1|2)))(\/\d{2})/;
    const regex = new RegExp(pattern)

    return regex.test(value.trim())
}


export function carrierIdRegex(value) {
    if (value.trim() === "") {
        return false;
    }
    const pattern = /^\/[0-9A-Z.+-]{7}$/
    const regex = new RegExp(pattern)

    return regex.test(value.trim())
}

export function taxIdRegex(value) {
    const cx = [1, 2, 1, 2, 1, 2, 4, 1];

    if (value.length !== 8 || !/^\d{8}$/.test(value)) {
        return false;
    }

    const cnum = value.split('').map(Number);
    let sum = 0;

    for (let index = 0; index < cnum.length; index++) {
        if (isNaN(cnum[index]) || cnum[index] < 0 || cnum[index] > 9) {
            return false;
        }
        sum += cc(cnum[index] * cx[index]);
    }

    return sum % 10 === 0 || (cnum[6] === 7 && (sum + 1) % 10 === 0);

    function cc(num) {
        let total = num;
        if (total > 9) {
            const [n1, n2] = total.toString().split('').map(Number);
            total = n1 + n2;
        }
        return total;
    }
}


/*export function taxIdRegex(value) {
    const cx = [1, 2, 1, 2, 1, 2, 4, 1];
    const cnum = value.split('');
    let sum = 0;

    function cc(num) {
        let total = num;
        if (total > 9) {
            let s = total.toString();
            const n1 = s.substring(0, 1) * 1;
            const n2 = s.substring(1, 2) * 1;
            total = n1 + n2;
        }
        return total;
    }

    if (value.length !== 8) {
        return false;
    }

    for (let index = 0; index < cnum.length; index++) {
        if (value.charCodeAt(index) < 48 || value.charCodeAt(index) > 57) {
            return false;
        }
        sum += cc(cnum[index] * cx[index]);
    }

    return sum % 10 === 0 || (cnum[6] === '7' && (sum + 1) % 10 === 0);
}*/
