// plugins/element-plus.ts
import { defineNuxtPlugin } from '#app'
import ElementPlus from 'element-plus'
import zhTw from 'element-plus/dist/locale/zh-tw.mjs'

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.use(ElementPlus, {
        locale: zhTw
    })
})
